<template>
    <div class="content-box">
        <div class="content-left">
            <header-entry>已付款</header-entry>
            <div class="content-border content-document">
                <div v-for="orderInfo in hasPaidOrderInfos" class="content-document-item">
                    <span class="content-document-item-name" @click="onOrderClick(orderInfo)">{{ orderInfo.OrderName }}</span>
                    <span class="content-document-item-date">{{ orderInfo.OrderCreateTime }}</span>
                </div>
            </div>
            <header-entry>待付款</header-entry>
            <div class="content-border content-document">
                <div v-for="orderInfo in waitPaidOrderInfos" class="content-document-item">
                    <span class="content-document-item-name" @click="onOrderClick(orderInfo)">{{ orderInfo.OrderName }}</span>
                    <span class="content-document-item-date">{{ orderInfo.OrderCreateTime }}</span>
                </div>
            </div>
            <header-entry>已取消</header-entry>
            <div class="content-border content-document">
                <div v-for="orderInfo in cancelPaidOrderInfos" class="content-document-item">
                    <span class="content-document-item-name" @click="onOrderClick(orderInfo)">{{ orderInfo.OrderName }}</span>
                    <span class="content-document-item-date">{{ orderInfo.OrderCreateTime }}</span>
                </div>
            </div>
        </div>
        <div class="content-right">
            <school-hot></school-hot>
        </div>
    </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import SchoolHot from "@/components/PC/SchoolHot";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "PCModule",
    components: {
        SchoolHot,
        HeaderEntry,
    },
    data() {
        return {
            hasPaidOrderInfos: [],
            waitPaidOrderInfos: [],
            cancelPaidOrderInfos: [],
        };
    },
    methods: {
        //获取路由参数
        getRoutParams() {

        },
        //查询订单列表
        getOrderInfos() {
            if (!this.$store.getters.isLogin) {
                return
            }
            //未付款
            var model1 = {
                OrderState: 0,
                PageIndex: 1,
                PageSize: 100,
            };
            this.$axios
                .post(Config.order.list, model1)
                .then((res) => {
                    this.waitPaidOrderInfos = res.data.Data.OrderInfos;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
            //已付款
            var model2 = {
                OrderState: 1,
                PageIndex: 1,
                PageSize: 100,
            };
            this.$axios
                .post(Config.order.list, model2)
                .then((res) => {
                    this.hasPaidOrderInfos = res.data.Data.OrderInfos;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
            //已取消
            var model3 = {
                OrderState: 2,
                PageIndex: 1,
                PageSize: 100,
            };
            this.$axios
                .post(Config.order.list, model3)
                .then((res) => {
                    this.cancelPaidOrderInfos = res.data.Data.OrderInfos;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //点击订单
        onOrderClick(orderInfo) {
            if (orderInfo.ProductType == 0) {
                this.$router.push({
                    name: 'Document1',
                    params: {
                        proxyCode: this.$store.state.proxyCode,
                        documentGuid: orderInfo.ProductGuid
                    }
                });
            } else if (orderInfo.ProductType == 2) {
                this.$router.push({
                    name: 'Course1',
                    params: {
                        proxyCode: this.$store.state.proxyCode,
                        courseGuid: orderInfo.ProductGuid
                    }
                });
            }
        }
    },
    mounted() {
        this.getOrderInfos();
    }
};
</script>
<style scoped>
.content-box {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.content-left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.content-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.content-border {
    border: 1px solid #e6e6e6;
    padding: 10px 30px;
}

.content-document {

    margin: 10px 0;
}

.content-document-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #555;
    font-size: 14px;
    margin: 10px 0;
}

.content-document-item-name {
    width: calc(100% - 200px);
    cursor: pointer;
    color: #555;
    font-size: 14px;
}

.content-document-item-name:hover {
    color: #FE5E03;
}

.content-document-item-date {
    color: #555;
    font-size: 14px;
}
</style>