<template>
    <div>
        <van-sticky>
            <title-bar2 title="我的订单" />
        </van-sticky>
        <van-tabs v-model="active" :offset-top="45" sticky :animated="false" @change="onReload">
            <van-tab title="待付款">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-swipe-cell v-for="(item, index) in orderInfos" :key="index">
                        <van-card :thumb="require('@/assets/images/mt/thumb.png')" :centered="false" :title="item.OrderName" :price="item.OrderAmount.toFixed(2)" desc="状态：等待买家付款" num="1" @click="onOrderClick(item)">
                            <template #footer>
                                <van-button plain round hairline size="small" type="primary" @click.stop="onOrderDetails(item, index)">订单详情</van-button>
                                <van-button plain round hairline size="small" type="info" @click.stop="onCancelOrder(item, index)">取消订单</van-button>
                                <van-button plain round hairline size="small" type="danger" @click.stop="onPayOrder(item, index)">立即付款</van-button>
                            </template>
                        </van-card>
                        <template #right>
                            <van-button square type="danger" text="删除" class="delete-button" @click="onDeleteOrder(item, index)" />
                        </template>
                    </van-swipe-cell>
                </van-list>
            </van-tab>
            <van-tab title="已付款">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-swipe-cell v-for="(item, index) in orderInfos" :key="index">
                        <van-card :thumb="require('@/assets/images/mt/thumb.png')" :centered="false" :title="item.OrderName" :price="item.OrderAmount.toFixed(2)" desc="状态：交易成功" num="1" @click="onOrderClick(item)">
                            <template #footer>
                                <van-button plain round hairline size="small" type="primary" @click.stop="onOrderDetails(item, index)">订单详情</van-button>
                            </template>
                        </van-card>
                        <template #right>
                            <van-button square type="danger" text="删除" class="delete-button" @click="onDeleteOrder(item, index)" />
                        </template>
                    </van-swipe-cell>
                </van-list>
            </van-tab>
            <van-tab title="已取消">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-swipe-cell v-for="(item, index) in orderInfos" :key="index">
                        <van-card :thumb="require('@/assets/images/mt/thumb.png')" :centered="false" :title="item.OrderName" :price="item.OrderAmount.toFixed(2)" desc="状态：交易取消" num="1" @click="onOrderClick(item)">
                            <template #footer>
                                <van-button plain round hairline size="small" type="primary" @click.stop="onOrderDetails(item, index)">订单详情</van-button>
                            </template>
                        </van-card>
                        <template #right>
                            <van-button square type="danger" text="删除" class="delete-button" @click="onDeleteOrder(item, index)" />
                        </template>
                    </van-swipe-cell>
                </van-list>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Manager from "@/manager";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
    },
    data() {
        return {
            active: 1,
            index: 0,
            loading: false,
            finished: false,
            orderInfos: [],
        };
    },
    methods: {
        //重新加载列表
        onReload() {
            this.finished = false;
            this.loading = true;
            this.index = 0;
            this.orderInfos = [];
            this.onLoad();
        },
        //加载当前列表
        onLoad() {
            if (!this.$store.getters.isLogin) {
                return
            }
            let tab = this.active;
            let model = {
                OrderState: tab,
                PageIndex: ++this.index,
                PageSize: 20,
            };
            this.$axios
                .post(Config.order.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.orderInfos.push(...data.OrderInfos);
                    this.loading = false;
                    if (this.index >= data.PageCount) {
                        this.finished = true;
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //点击删除订单
        onDeleteOrder(row, index) {
            this.$dialog
                .confirm({ title: "提示", message: "确定要删除该订单吗？" })
                .then(() => {
                    var model = {
                        OrderGuid: row.OrderGuid,
                    };
                    this.$axios
                        .post(Config.order.delete, model)
                        .then((res) => {
                            this.orderInfos.splice(index, 1);
                            this.$toast.success("订单删除成功。");
                        })
                        .catch((error) => {
                            this.$dialog({ title: "错误", message: error.message });
                        });
                });
        },
        //点击取消订单
        onCancelOrder(row, index) {
            this.$dialog
                .confirm({ title: "提示", message: "确定要取消该订单吗？" })
                .then(() => {
                    var model = {
                        OrderGuid: row.OrderGuid,
                    };
                    this.$axios
                        .post(Config.order.cancel, model)
                        .then((res) => {
                            this.orderInfos.splice(index, 1);
                            this.$toast.success("订单取消成功。");
                        })
                        .catch((error) => {
                            this.$dialog({ title: "错误", message: error.message });
                        });
                }).catch(() => { });
        },
        //点击支付订单
        onPayOrder(row, index) {
            this.$dialog
                .confirm({ title: "订单确认", message: "请确认订单信息，付款后不支持退款。" })
                .then(() => {
                    var model = {
                        OrderGuid: row.OrderGuid,
                        PayFinishUrl: Manager.goPayFinishUrl(this.$store.state.proxyCode),
                    };
                    this.$axios
                        .post(Config.pay.url, model)
                        .then((res) => {
                            this.$router.push({
                                name: "PayBefore",
                                params: {
                                    proxyCode: this.$store.state.proxyCode
                                },
                                query: {
                                    payUrl: res.data.Data.DocinPayUrl
                                }
                            });
                        })
                        .catch((error) => {
                            this.$dialog({ title: "错误", message: error.message });
                        });
                }).catch(() => { });
        },
        //点击订单详情
        onOrderDetails(row, index) {
            this.$router.push({
                name: 'OrderDetails',
                params: {
                    proxyCode: this.$store.state.proxyCode
                },
                query: {
                    orderGuid: row.OrderGuid
                }
            });
        },
        //点击订单
        onOrderClick(orderInfo) {
            if (orderInfo.ProductType == 0) {
                this.$router.push({
                    name: 'Document1',
                    params: {
                        proxyCode: this.$store.state.proxyCode,
                        documentGuid: orderInfo.ProductGuid
                    }
                });
            } else if (orderInfo.ProductType == 2) {
                this.$router.push({
                    name: 'Course1',
                    params: {
                        proxyCode: this.$store.state.proxyCode,
                        courseGuid: orderInfo.ProductGuid
                    }
                });
            }
        }
    },
    mounted() {
    }
};
</script>
<style scoped>
.delete-button {
    height: 100%;
}

.van-card {
    background-color: white;
}
</style>